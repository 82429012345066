/* eslint-disable max-len */

type CashMeAlternativeProps = {
  className?: string
}

export const CashMeAlternative = ({ className }: CashMeAlternativeProps) => (
  <svg
    width="200"
    height="39"
    viewBox="0 0 200 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5649_219348)">
      <path
        d="M199.946 20.5565C199.946 12.3653 193.962 6.6853 185.323 6.6853C176.112 6.6853 169.711 13.1543 169.711 22.4409C169.711 31.7274 176.476 38.191 186.164 38.191C191.003 38.191 195.375 36.5697 198.652 33.7558L194.789 26.9211C192.812 28.2776 189.846 29.8431 186.151 29.8431C182.198 29.8431 179.786 28.0939 179.323 25.5646H199.22C199.684 23.9351 199.928 22.2508 199.946 20.5565ZM179.281 19.2523C179.652 16.5969 182.178 14.8171 185.06 14.8171C187.975 14.8171 189.901 16.6438 189.901 19.2523H179.281Z"
        fill="white"
      />
      <path
        d="M148.112 18.7297L138.224 0.833984H128.439V37.3568H138.536V18.0469L146.551 31.9812H149.672L157.688 17.9208V37.3568H167.783V0.833984H157.999L148.112 18.7297Z"
        fill="white"
      />
      <path
        d="M53.1901 6.67798C48.2449 6.67798 43.2494 8.71363 39.2422 12.2085L43.9269 18.782C46.4749 16.7535 49.2368 15.3394 51.7831 15.3394C54.3851 15.3394 56.155 16.8004 56.155 19.1477V19.6701H49.9089C42.4677 19.6701 37.9395 22.8515 37.9395 28.7477C37.9395 34.2277 41.8424 38.1909 47.979 38.1909C51.6735 38.1909 54.7966 36.7299 56.8774 34.3826V37.3568H66.2449V19.825C66.2503 11.6338 60.9421 6.67798 53.1901 6.67798ZM56.155 26.4004C56.155 29.2683 54.2287 31.0427 51.4183 31.0427C49.3375 31.0427 48.2431 30.0519 48.2431 28.4342C48.2431 26.8165 49.2332 25.8257 50.8972 25.8257H56.155V26.4004Z"
        fill="#00224B"
      />
      <path
        d="M19.3609 0C13.2711 0 8.06714 2.76524 4.47685 6.7825C3.38431 7.98227 3.01953 8.71366 3.01953 9.65222C3.01953 10.4863 3.37892 11.1654 3.95574 11.5311L10.5128 15.6006C10.9139 15.8253 11.2674 16.1263 11.5532 16.487C12.5954 13.0426 15.8138 10.643 19.6718 10.643C22.7949 10.643 25.6574 12.1563 27.5316 15.1827L36.0132 9.80895C32.7356 3.96321 26.6457 0 19.3609 0Z"
        fill="#00224B"
      />
      <path
        d="M19.6716 27.548C15.8208 27.548 12.6474 25.2061 11.6052 21.7563C11.3985 22.0176 10.8864 22.331 10.5126 22.5382L3.95378 26.6077C3.27813 27.0256 3.01758 27.6525 3.01758 28.3821C3.01758 29.2684 3.43267 30.1043 4.4749 31.3563C8.06878 35.6798 13.4255 38.1911 19.2547 38.1911C26.6438 38.1911 32.7318 34.2279 36.0112 28.3821L27.5297 23.0084C25.6572 26.0348 22.7947 27.548 19.6716 27.548Z"
        fill="#00224B"
      />
      <path
        d="M9.78475 21.3382C10.6688 20.8176 11.19 20.1907 11.19 19.0954C11.19 18.0001 10.721 17.3732 9.78475 16.8004L3.1756 12.7903C2.53634 12.3578 2.04565 11.7381 1.77039 11.0159C0.587871 13.5434 -0.0168345 16.3037 0.000407018 19.0954C-0.0179817 21.8728 0.586941 24.6187 1.77039 27.1299C1.87433 26.755 2.05226 26.4047 2.29362 26.0999C2.53499 25.7952 2.8349 25.542 3.1756 25.3555L9.78475 21.3382Z"
        fill="white"
      />
      <path
        d="M115.118 6.67792C111.524 6.67792 108.77 8.24339 106.687 11.6337V0.833984H96.5918V37.3568H106.687V20.2429C106.687 17.6866 109.082 15.3393 111.789 15.3393C113.869 15.3393 115.431 16.8003 115.431 18.7819V37.3568H125.526V18.7819C125.526 11.2698 121.208 6.67792 115.118 6.67792Z"
        fill="#00224B"
      />
      <path
        d="M85.0887 18.7819L81.9674 17.5822C80.6143 17.0597 79.8847 16.6958 79.8847 15.9608C79.8847 15.179 80.6143 14.7611 82.0698 14.7611C84.2046 14.7611 86.4939 15.6474 88.5765 17.4218L93.572 11.0573C90.8658 8.29203 86.6502 6.67432 81.8111 6.67432C74.7329 6.67432 69.5811 10.171 69.5811 16.1176C69.5811 20.8662 72.8587 23.4747 78.9485 25.822L82.4346 27.1785C83.6314 27.6487 84.2046 28.0126 84.2046 28.6918C84.2046 29.5781 83.2684 30.1527 81.4463 30.1527C78.5838 30.1527 75.617 28.744 72.9629 26.2922L68.0195 32.4478C71.1929 36.0994 76.0339 38.1873 81.4966 38.1873C89.1462 38.1873 94.5064 34.1178 94.5064 28.2792C94.5082 23.6873 91.2306 21.1292 85.0887 18.7819Z"
        fill="#00224B"
      />
    </g>
    <defs>
      <clipPath id="clip0_5649_219348">
        <rect width="200" height="38.191" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
