/* eslint-disable max-len */

type CashMeLightBlueProps = {
  className?: string
}

export const CashMeLightBlue = ({ className }: CashMeLightBlueProps) => (
  <svg
    width="105"
    height="20"
    viewBox="0 0 105 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_652_8060)">
      <path
        d="M104.972 10.7651C104.972 6.4755 101.831 3.50098 97.2949 3.50098C92.459 3.50098 89.0986 6.88871 89.0986 11.7519C89.0986 16.6151 92.6505 20 97.7364 20C100.277 20 102.572 19.151 104.293 17.6774L102.265 14.0981C101.227 14.8085 99.6694 15.6283 97.7298 15.6283C95.6543 15.6283 94.3883 14.7123 94.1449 13.3878H104.591C104.834 12.5344 104.962 11.6524 104.972 10.7651ZM94.1232 10.0821C94.3175 8.69154 95.6439 7.75947 97.1571 7.75947C98.6873 7.75947 99.6986 8.71607 99.6986 10.0821H94.1232Z"
        fill="white"
      />
      <path
        d="M77.7599 9.80822L72.5684 0.436523H67.4316V19.5629H72.7326V9.45067L76.9401 16.7478H78.5788L82.7873 9.38464V19.5629H88.0873V0.436523H82.9505L77.7599 9.80822Z"
        fill="white"
      />
      <path
        d="M27.9246 3.49707C25.3283 3.49707 22.7057 4.56311 20.6019 6.3933L23.0614 9.83575C24.3991 8.77348 25.8491 8.03292 27.1859 8.03292C28.5519 8.03292 29.4812 8.79801 29.4812 10.0273V10.3008H26.2019C22.2953 10.3008 19.918 11.9669 19.918 15.0546C19.918 17.9244 21.967 19.9999 25.1887 19.9999C27.1283 19.9999 28.768 19.2348 29.8604 18.0056V19.5631H34.7783V10.382C34.7812 6.09235 31.9944 3.49707 27.9246 3.49707ZM29.4812 13.8254C29.4812 15.3273 28.4699 16.2565 26.9944 16.2565C25.9019 16.2565 25.3274 15.7376 25.3274 14.8905C25.3274 14.0433 25.8472 13.5244 26.7208 13.5244H29.4812V13.8254Z"
        fill="#00BFFF"
      />
      <path
        d="M10.1642 0C6.96704 0 4.23496 1.44811 2.35006 3.55189C1.77647 4.18019 1.58496 4.56321 1.58496 5.05472C1.58496 5.49151 1.77364 5.84717 2.07647 6.03868L5.51892 8.16981C5.72952 8.28744 5.91507 8.44511 6.06515 8.63396C6.61232 6.83019 8.30194 5.57358 10.3274 5.57358C11.967 5.57358 13.4699 6.36604 14.4538 7.95094L18.9067 5.13679C17.1859 2.07547 13.9887 0 10.1642 0Z"
        fill="#00BFFF"
      />
      <path
        d="M10.3274 14.4266C8.30568 14.4266 6.63965 13.2002 6.09247 11.3936C5.98398 11.5303 5.71512 11.6945 5.51889 11.803L2.07549 13.9341C1.72078 14.153 1.58398 14.4813 1.58398 14.8634C1.58398 15.3275 1.80191 15.7653 2.34908 16.4209C4.23587 18.6851 7.04814 20.0002 10.1085 20.0002C13.9878 20.0002 17.184 17.9247 18.9057 14.8634L14.4529 12.0492C13.4698 13.6341 11.967 14.4266 10.3274 14.4266Z"
        fill="#00BFFF"
      />
      <path
        d="M5.13701 11.1742C5.60116 10.9016 5.87474 10.5733 5.87474 9.99969C5.87474 9.4261 5.62852 9.0978 5.13701 8.7978L1.66719 6.6978C1.33158 6.47128 1.07397 6.14679 0.929459 5.76855C0.308633 7.09218 -0.00883815 8.53772 0.000213685 9.99969C-0.00944042 11.4541 0.308144 12.8921 0.929459 14.2072C0.984028 14.0109 1.07744 13.8275 1.20415 13.6678C1.33087 13.5082 1.48833 13.3757 1.66719 13.278L5.13701 11.1742Z"
        fill="white"
      />
      <path
        d="M60.4374 3.4969C58.5506 3.4969 57.1043 4.31671 56.0109 6.09218V0.436523H50.7109V19.5629H56.0109V10.6007C56.0109 9.26199 57.2685 8.03275 58.6892 8.03275C59.7817 8.03275 60.6015 8.79784 60.6015 9.83558V19.5629H65.9015V9.83558C65.9015 5.90162 63.6345 3.4969 60.4374 3.4969Z"
        fill="#00BFFF"
      />
      <path
        d="M44.6723 9.83568L43.0336 9.20738C42.3232 8.9338 41.9402 8.74323 41.9402 8.35832C41.9402 7.94889 42.3232 7.73002 43.0874 7.73002C44.2081 7.73002 45.41 8.19417 46.5034 9.12342L49.126 5.7904C47.7053 4.34229 45.4921 3.49512 42.9515 3.49512C39.2355 3.49512 36.5307 5.32625 36.5307 8.4404C36.5307 10.9272 38.2515 12.2932 41.4487 13.5225L43.2789 14.2329C43.9072 14.4791 44.2081 14.6696 44.2081 15.0253C44.2081 15.4895 43.7166 15.7904 42.76 15.7904C41.2572 15.7904 39.6996 15.0527 38.3062 13.7687L35.7109 16.9923C37.377 18.9045 39.9185 19.9979 42.7864 19.9979C46.8024 19.9979 49.6166 17.8668 49.6166 14.8093C49.6175 12.4045 47.8968 11.0649 44.6723 9.83568Z"
        fill="#00BFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_652_8060">
        <rect width="105" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
